<template>
  <div>
    <Navbar />
    <div class="columns">
      <SideBar class="menu" />
      <section class="container">
        <Header class="header" />
        <div class="card-sup">
          <vs-col>
            <div class="card-info">
              <h3>
                <font-awesome-icon
                  style="margin-right: 3.5px"
                  :icon="['fas', 'archive']"
                />
                Documento Financeiro
              </h3>
              <vs-divider />
              <vs-row class="pd-t">
                <vs-col>
                  <div class="alert alert-info">
                    <h3>
                      <font-awesome-icon
                        style="margin-right: 1.5px"
                        :icon="['fas', 'radiation']"
                      />
                      ATENÇÃO
                    </h3>
                    <p style="margin-top: 5px;">
                      Nesta área, apenas os pagamentos realizados no ano anterior ({{ this.anoPassado }}) serão emitidos.
                    </p>
                  </div>
                </vs-col>
              </vs-row>

              <vs-row class="pd-table">
                <vs-col>
                  <vs-table
                    class="table_intranet"
                    :data="financeDocuments"
                    hoverFlat
                  >
                    <template slot="thead">
                      <vs-th>DESCRIÇÃO</vs-th>
                      <vs-th width="15%">DOCUMENTO</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr v-for="(tr, index) in data" :key="index">
                        <vs-td>
                          <font-awesome-icon
                            class="icon"
                            :icon="data[index].icon"
                          />
                          {{ data[index].name }}
                        </vs-td>
                        <vs-td>
                          <vs-button class="button-table" @click="getReportsAmountsPaid()">
                            <font-awesome-icon
                              color="white"
                              style="margin-right: 3.5px;"
                              :icon="['fas', 'arrow-alt-circle-down']"
                            />
                            .PDF
                          </vs-button>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SimpleCrypto from "simple-crypto-js";
import Documents from "../../services/documents";
import Person from "../../services/person";

export default {
  name: "DocumentosFinanceiros",
  data: () => ({
    personalData: [],
    anoPassado: null,
    financeDocuments: [
      { name: "Declaração de Valores Pagos", icon: "thumbs-up" },
    ],
    paymentAmountsPaid: [],
  }),

  mounted() {
    this.date = new Date();
    this.anoPassado = this.date.getFullYear() - 1;

    this.$vs.loading({ text: "Carregando seus Dados Financeiros 🪙" });
    new Person()
      .getPersonalData()
      .then(({ data }) => {
        const secretKey = "QI-System-@@";
        const simpleCrypto = new SimpleCrypto(secretKey);
        this.personalData = [simpleCrypto.decrypt(data)];

        this.ra = this.personalData[0].RA;
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.error = response.data;
          console.log(response);
        }
      })
      .finally(() => {
        this.$vs.loading.close();
      });
  },

  methods: {
    getReportsAmountsPaid() {
      let tableName = "SALUNO";
      let filterTag = "SALUNO.CODCOLIGADA = 1 AND SALUNO.RA = '" + this.ra + "'";
      let filename = "VALORES-PAGOS-" + this.ra + ".pdf";

      const payloadAmountsPaid = {
        colligateId: 0,
        reportId: 740,
        filename: filename,
        tableName: tableName,
        filterTag: filterTag,
      };

      this.$vs.loading({ text: "Baixando Declaração de Valores Pagos 📩" });
      new Documents()
        .getReports(payloadAmountsPaid)
        .then(({ data }) => {
          this.paymentAmountsPaid = Array.isArray(data) ? data : [data];

          const linkSource = `data:application/pdf;base64,${this.paymentAmountsPaid}`;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch(({ response }) => {
          if (response && response.data) {
            this.error = response.data;
          }
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>

<style scoped>
tr:nth-child(even) {
  background-color: #d4d4d4;
}

.icon {
  font-size: 1.5em;
  margin-right: 0.75%;
}

.card-sup {
  margin-left: 8.5px;
  margin-right: 8.5px;
  margin-bottom: 8.5px;
}

.selectYear {
  margin: auto;
  width: 97.5%;
}
</style>
